<template>
  <div class="bg-bg_color p-5">
    <div class="text-lg font-medium">{{ t('verify_phone') }}</div>

    <div v-if="!form.sent">
      <div v-if="form.sending">
        <MevcutLoadingIndicator :loading="form.sending">
          {{ t('sending') }}
        </MevcutLoadingIndicator>
      </div>
      <div v-else>
        <!-- Resend Code -->
        <MevcutSubmitButton
          :title="t('resend')"
          :loading="form.sending"
          @submit="resendHandler"
        />
        <div v-if="form.remain > 0" class="text-red-500 text-sm my-2">
          {{ t('try_after_seconds', { second: form.remain }) }}
        </div>
      </div>
    </div>
    <div v-else>
      <!-- Code Input -->
      <AuthTextField
        v-model="form.code"
        :label="t('sms_code')"
        input-class="text-center"
        placeholder="- - - - - -"
        error="verify_phone-code"
      />

      <!-- Verify -->
      <MevcutResponseError
        class="my-2"
        api-key="verify_phone"
        :except="['verify_phone-code']"
      />

      <MevcutSubmitButton
        :title="t('verify')"
        type="button"
        :loading="form.verifying"
        @submit="verify"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const { form, resendHandler, verifyHandler, clear } = useVerifyPhone()

const emit = defineEmits(['close'])

const { t } = useI18n()

onMounted(() => {
  resendHandler()
})

onUnmounted(() => {
  clear()
})

async function verify() {
  if (await verifyHandler()) {
    emit('close')
  }
}
</script>
